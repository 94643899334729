@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800');

$maxwidth: 1024px;
$pad:15px;
$smallpad:7.5px;
$mar:15px;
$smallmar:7.5px;
$menubgcolor: #de0000;
$menubghover: #a31111;
$menutext: #FFF;

$color1:#de0000; //red
$color2:#a31111; //darkred
$color3: #f8f5f5; //lightgrey
$color4: #666; //darkgrey
$color5: #ffffff; //white

$font:'Open Sans', sans-serif;

.pad { padding: $pad; }
.pad-left { padding-left: $pad; }
.pad-right { padding-right: $pad; }
.pad-top { padding-top: $pad; }
.pad-bot { padding-bottom: $pad; }

.small-pad { padding: $smallpad; }
.small-pad-left { padding-left: $smallpad; }
.small-pad-right { padding-right: $smallpad; }
.small-pad-top { padding-top: $smallpad; }
.small-pad-bot { padding-bottom: $smallpad; }

.mar { margin: $mar; }
.mar-left { margin-left: $mar; }
.mar-right { margin-right: $mar; }
.mar-top { margin-top: $mar; }
.mar-bot { margin-bottom: $mar; }

/* TO IMPORT TO LAYOUT.CSS*/
.small-mar-right { margin-right: $mar/2;  }
.small-mar-left {  margin-left: $mar/2;  }


.row {
  margin-right: 0;
  margin-left: 0;

  & > [class^="col-"],
  & > [class*=" col-"] {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
}

body {
  width:100%;
  overflow-x: hidden;
}

.container {
  width: 100%;
  margin:0;
  padding:0;

  overflow-x: hidden;

}

.max-width {
  max-width: $maxwidth;
  margin:0 auto;
  overflow-x: visible;
}

.about-team .max-width {
  overflow-x: visible;
}

/* Page */
.page-title{
  background-color: #eaeaea;
}
.black-overlay{
  background-color: rgba(000,000,000,1);
}
.bezel-white-flipped {
  position: relative;
  z-index: 99;
  width: 120%;
  height: 100px;
}

/* Text */
h1 {
  text-align: center;
  margin-bottom: 0;
}
.bgcheck {
  color: #000;
}
.bgcheck.background--light {
  color: #000;
  text-shadow: 1px 1px 13px rgba(255, 255, 255, 1);
}
.bgcheck.background--dark {
  color: #FFF;
  text-shadow: 1px 1px 13px rgba(0, 0, 0, 1);
}
h2 {
  text-transform: uppercase;
}
h5 {
  text-align: center;
  font-size: 16px;
  padding: $pad;
}
/* Header */
header {
  padding:$pad $pad 0 $pad;
  line-height: 1.1;
}
.header-tele-top {
  color: $color1;
}
.header-tele {
  color: #000;
  font-size: 2em;
}
#header-logo {
  max-height: 120px;
  padding:$pad;
}

.header-social {

    .fa {
      font-size: 20pt;
      margin-left:$mar/3;
    }
    a {
      color: $color1;
    }
    a:hover {
      color: $color2;
    }

}
.header-right {
  padding: $pad;
}
.header-title {
  background-color: #f6f6f6;
  padding: $pad;
}
.header-image {
  position: relative;
  height:300px;
  background-size: cover;
}
.header-image h1 {
  color: #FFFFFF;
  text-align: center;
  position: relative;
  top:35%;
  text-transform: uppercase;
}

/* Home Testiminials */
 .home-testimonial-wrap {
   color: #FFF !important;
   text-shadow: 1px 1px 2px rgba(0, 8, 0, 1);

   h4 {
     color: #FFF !important;
     text-shadow: 1px 1px 2px rgba(0, 8, 0, 1);
   }
 }
 .thidden {
   display: none;
 }
/* Menu */
.menu-wrap {
  background-color: $menubgcolor;
}
.nav-link:link, .nav-link:hover, .nav-link:visited {
  color: $menutext !important;
  padding:$pad;
}
.nav-link:hover {
  background-color: $menubghover;
}
.navbar, .navbar-light {
  width:auto;
  float: none;
  margin:0px auto;
  padding:0px;
  border-radius: 0px;
}
.nav {
  width: auto;
  margin:0 auto;
  text-align: center;
  display: table;
}
.nav-link{
  font-size: 18px !important;
  text-transform: uppercase !important;
}
/* Footer */
.footer2 {
  background-color: $color3;
  border-top:2px solid $color4;
  padding: $pad;
}
.footer1 {
  border-top:$color1 2px solid;
  font-size: 0.8em;
}
.footer1 {

  ul {
    margin:0px;
    padding:0px;
    list-style: none;
  }
  li a {
    color: $color4;
    text-decoration: none;
    font-size: 1em;
  }
  li a:hover {
    color: $color2;
  }
  h4 {
    font-size: 1.5em;
    color: $color1;
    text-transform: uppercase;
  }

}


/* Buttons */
.btn-primary {
  background-color: $color1;
  text-transform: uppercase;
  border-color: $color4;
}
.btn-primary:hover, .btn-primary:active, .btn-primary:focus {
  background-color: $color2;
  border-color: $color4;
}
.btn-primary:focus, .btn-primary:active:focus {
  outline-color: $color3;
  background-color: $color2;
  border-color: $color4;
}
.btn-full {
  width:100%;
}

/* Alerts */
.alert ul {
  margin:0px;
  padding:0px;
  list-style: none;
}

/* Logins */
.login-loading {
  padding:30px;
  text-align: center;
  display: none;
}
.login-success {
  text-align: center;
  display: none;
}

/* Bezels */
.bezel-header {
  position: relative;
  margin-bottom:15px;

}
.bezel-header img {
  position: relative;
  top:0px;
  left:-10px;
  z-index: 5;
  width:120%;
  height:100px;
}
.bezel-header h3 {
  position: absolute;
  top:20%;
  left:10px;
  right: 10px;
  color: #FFF;
  z-index: 10;
  text-transform: uppercase;
  padding:$pad;
}
.bezel-white {
  position: absolute;
  bottom:0;
  left:-10px;
  z-index: 5;
  width:120%;
  height:100px;
}

/* CONTACT PAGE */
.map-wrapper {
  height: 400px;
}
.contact-form input, .contact-form textarea {
  padding: 10px 15px;
  height: auto;
  background-color: #eaeaea;
  color: #333333;
  border: 0;
  border-radius: 0;
  box-shadow: none !important;
}
.contact-form input:focus, .contact-form textarea:focus {
  background-color: #f6f6f6;
}

.contact-form ::-webkit-input-placeholder { color: #999999 !important; }
.contact-form :-moz-placeholder { color: #999999 !important; }
.contact-form ::-moz-placeholder { color: #999999 !important; }
.contact-form :-ms-input-placeholder { color: #999999 !important; }

.contact-form input:focus::-webkit-input-placeholder { color: #f6f6f6 !important; }
.contact-form input:focus:-moz-placeholder { color:#f6f6f6 !important; }
.contact-form input:focus::-moz-placeholder { color: #f6f6f6 !important; }
.contact-form input:focus:-ms-input-placeholder { color: #f6f6f6 !important; }

.contact-form textarea:focus::-webkit-input-placeholder { color: #f6f6f6 !important; }
.contact-form textarea:focus:-moz-placeholder { color:#f6f6f6 !important; }
.contact-form textarea:focus::-moz-placeholder { color: #f6f6f6 !important; }
.contact-form textarea:focus:-ms-input-placeholder { color: #f6f6f6 !important; }

.social-icons i {
  font-size: 40px;
}
.social-icons a {
  color: #de0000;
}
.social-icons a:hover {
  color: #a31111;
}
.social-icons {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
}
.contact-info {
  min-height: 144px;
  position: relative;
}
.border-left {
  border-left: 1px solid rgba(0,0,0,0.1);
}
/* HOME PAGE */
.home-course-bg {
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% auto;
  padding: $pad;
  -webkit-transition:all 1s ease-in;
  -moz-transition:all 1s ease-in;
  -o-transition:all 1s ease-in;
  -ms-transition:all 1s ease-in;
  transition:all 1s ease-in;
}
.home-course-wrapper {
  font-family: $font;
  background-color: rgba(0,0,0,0.8);
  padding: $pad;
  color: #FFFFFF;
  text-align: center;
}
.home-course-wrap h3 {
  font-family: $font;
  text-transform: uppercase;
  text-align: center;
  font-weight: bold;
  margin-bottom: 0;
}
.home-course-wrap .course-name {
  min-height:80px;
}
.home-course-wrap .course-image-wrap {
  width:100%;
  height:280px;
  overflow: hidden;
}
.home-course-wrap .course-image-wrap img {
  min-width:100%;
  min-height:280px;
  width:100%;
  height:auto;
}
.course-name {
  margin-bottom: $mar;
  margin-top: $mar;
}
.course-description {
  font-family: $font;
  text-align: center;
  font-weight: bold;
  color: #FFFFFF;
  margin-bottom: $mar;
}
.course-buttons {
  margin-top: $mar;
  min-height: 45px;
}
.home-course-button {
  text-transform: uppercase;

  padding: $smallpad;
  color: #FFFFFF;
}
.home-course-button:hover {
  cursor: pointer;
  background-color: $color2;
}
.home-button-1 {

  display: block;
  border: 3px solid #FFFFFF;
  font-weight: bold;
  text-align: center;
}
.home-button-2 {

  display: block;
  border: 3px solid $color1;
  background-color: $color1;
  color: $color5;
  font-weight: bold;
  text-decoration: none;
  text-align: center;
}
.home-button-1:hover {
  border: 3px solid #FFFFFF;
  background-color: #ffffff;
  color: $color1;
  font-weight: bold;
  text-decoration: none;
}
.home-button-1 p:hover{
  text-decoration: none;
}
.home-button-1 a:hover{
  text-decoration: none;
}
.home-button-2:hover {
  border: 3px solid $color1;
  background-color: $color2;
  color: $color5;
  font-weight: bold;
  text-decoration: none;
}
.home-content-1 {
  padding: $pad $pad 0 $pad;
}
.home-about-area {
  min-height: 427.6px;
  position: relative;
}
.home-about-area h2 {
  text-align: center;
  font-weight: bold;
  margin-bottom: $mar;
  color: $color1;
  text-transform: uppercase;
}
.home-about-area h3 {
  font-size: 1.25rem;
}
.home-content-1 {
  background-color: rgba(255,255,255,0.8);
  padding: $pad $pad 1px $pad;
}
.home-content-2 {
  position: absolute;
  bottom: 0;
  left: $smallpad;
  right: 0;
  padding: $pad;
  background-color: #FFFFFF;
}


.home-apprenticeships-wrap {
  background-color: rgba(0,0,0,0.8);
  padding: $pad $pad 1px $pad;
  color: #FFFFFF;
  margin: $mar 0 25px 0;
}
.home-apprenticeships-wrap h3 {
  font-family: $font;
  text-transform: uppercase;
  text-align: center;
  font-weight: bold;
  margin-bottom: $mar;
  color: #FFFFFF;
}
.course-slide {
  margin: $mar;
  background-color: rgba(0,0,0,0.8);
  padding: $pad;
}
.course-slide a, .course-slide a:hover {
  color: #FFF;
  text-decoration: none;
}

.home-slider > .home-course-wrap ~ .home-course-wrap {
  display: none;
}

.course-slide-image {
  width:100%;
  height: 180px;
  overflow: hidden;
  position: relative;
}
.course-slide-image img {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
}
.course-slide-name {
  color: #FFFFFF;
  text-align: center;
  min-height:70px;
}
.cat-course-slide-name {
  min-height:75px;
  color: #FFFFFF;
  text-align: center;
  text-transform: uppercase;
}
.slide-left {
  position: absolute;
  left: -50px;
  top:30%;
  font-size: 72px;
  color: #dedede;
}
.slide-right {
  position: absolute;
  right: -50px;
  top:30%;
  font-size: 72px;
  color: #dedede;
}
.slide-left:hover, .slide-right:hover {
  cursor: pointer;
}
.home-apprenticeships-bg {
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% auto;
}
.testimonials-slider-bg {
  background-color: rgba(255,255,255,0.8);
}
.testimonials-slider .slide-left, .testimonials-slider .slide-right {
  color: #000000 !important;
}
/* Courses Page */
.courses-header {
  min-height: 440px !important;
  padding: 10px 0 20px 0;
}
.course-card-box {
  padding: 0 0 0px 0;
  text-align: center;
  margin: 0 5px 10px 5px;
}
.course-card-box a:hover, .course-card-box:hover {
  text-decoration: none;
}
.course-card-title {
  min-height: 48px;
}
.course-card-box a {
  color: #FFFFFF;
}
.course-card-box p {
  margin-bottom: 0;
}
.course-card-image {
  height: 250px;
  overflow: hidden;
  position: relative;
}
.course-card-image img {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
}
.course-card-text {
  margin-top: 10px;
  min-height: 90px;
  font-size: 13px;
}
.course-card-overlay {
  position: absolute;
  left: 0;
  right: 0;
  top: 182px;
  text-align: center;
  padding: 10px;
  background-color: rgba(0,0,0,0.7);
  color: #FFFFFF !important;
  text-transform: uppercase;
  font-weight: bold;
  transition-property: top, padding-top;
  transition-duration: 0.5s;
  -moz-transition-property: top, padding-top;
  -moz-transition-duration: 0.5s;
  -webkit-transition-property: top, padding-top;
  -webkit-transition-duration: 0.5s;
  height: 250px;
}
.course-card-box:hover .course-card-overlay {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding-top: 80px;
  display: block;
}
/* APPRENTICESHIP PAGE */
.apprenticeship-bg {
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% auto;
  padding: $pad;
  -webkit-transition:all 1s ease-in;
  -moz-transition:all 1s ease-in;
  -o-transition:all 1s ease-in;
  -ms-transition:all 1s ease-in;
  transition:all 1s ease-in;
  min-height: 300px;
}
.apprenticeship-bg h1 {
  margin-top: 60px;
  text-transform: uppercase;
}
.apprenticeships-list {
  background-color: rgba(255,255,255,0.8);
  margin-top: 15px;
  min-height: 392px;
  padding: $pad;
}
.apprenticeships-list ul {
  margin-bottom: 0px;
  margin-top: 15px;
}
.apprenticeships-list ul li, .apprenticeships-list ul li p, .apprenticeships-list ul li a {
  color: #000000;
  font-weight: bold;
  height: 24px;
  line-height: 24px;
  vertical-align: middle;
  list-style-image: url("/images/icons/list.png");
  margin-bottom: 25px;
}
/* Course View Page */
.course-header-image {
  position: relative;
  height:300px;
  background-size: cover;
  background-position: 0 30%;
}
.course-header-image h1 {
  text-align: center;
  position: relative;
  top:35%;
  text-transform: uppercase;
}
.course-view-contents {
  position: relative;
  top:-60px;
  z-index: 90;
}
.course-view-img {
  border: 6px solid #FFF;
}
.courses-view-dates {
  text-align: center;
  margin: 0 $mar
}
.courses-view-dates:hover {
  cursor: pointer;
}
.courses-slider {
  margin-bottom: 10px;
}

/* FAQS PAGE */
.faqs-panel {
  border-radius: 0px !important;
  margin-bottom: $mar;
}
.faqs-panel .card-header {
  background-color: $color1;
  border-radius: 0px;
  color: #FFFFFF;
  padding: $smallpad $pad;
}
.faqs-panel .card-header a {
  color: #FFFFFF;
  font-size: 1rem;
}
.faqs-panel .card-block {
  background-color: #f6f6f6;
  padding: $pad $pad 1px $pad;
}
.faqs-link:hover {
  text-decoration: none;
}
.faqs-link:focus {
  outline: none;
  outline: none;
  outline-offset: 0px;
  text-decoration: none;
}

/* WIDGET EDIT */
.widget-wrap {
  position: relative;
}
.widget-edit {
  display: none;
}
.widget-admin:hover  {
  display: block;
  border: 1px solid #000;
}
.widget-admin:hover .widget-edit {
  display: inline-block;
  position: absolute;
  right:0px;
  padding: 10px;
  background-color: rgba(0,0,0,0.8);
}
.widget-admin:hover a {
  color: #FFF;
}
.sdlogo {
  float: right;
  margin-right: $mar;
  max-width: 130px;
}

/* Calender  */
time.icon
{
  font-size: 1em; /* change icon size */
  display: block;
  position: relative;
  width: 7em;
  height: 7em;
  background-color: #fff;
  margin: 1em auto;
  border-radius: 0.6em;
  box-shadow: 0 1px 0 #bdbdbd, 0 2px 0 #fff, 0 3px 0 #bdbdbd, 0 4px 0 #fff, 0 5px 0 #bdbdbd, 0 0 0 1px #bdbdbd;
  overflow: hidden;
  -webkit-backface-visibility: hidden;
  -webkit-transform: rotate(0deg) skewY(0deg);
  -webkit-transform-origin: 50% 10%;
  transform-origin: 50% 10%;
}

time.icon *
{
  display: block;
  width: 100%;
  font-size: 1em;
  font-weight: bold;
  font-style: normal;
  text-align: center;
}

time.icon strong
{
  position: absolute;
  top: 0;
  padding: 0.4em 0;
  color: #fff;
  background-color: #de0000;
  border-bottom: 1px dashed #de0000;
  box-shadow: 0 2px 0 #de0000;
}

time.icon em
{
  position: absolute;
  bottom: 0.3em;
  color: #de0000;
}

time.icon span
{
  width: 100%;
  font-size: 2.8em;
  letter-spacing: -0.05em;
  padding-top: 0.8em;
  color: #2f2f2f;
}

time.icon:hover, time.icon:focus
{
  -webkit-animation: swing 0.6s ease-out;
  animation: swing 0.6s ease-out;
}

@-webkit-keyframes swing {
  0%   { -webkit-transform: rotate(0deg)  skewY(0deg); }
  20%  { -webkit-transform: rotate(12deg) skewY(4deg); }
  60%  { -webkit-transform: rotate(-9deg) skewY(-3deg); }
  80%  { -webkit-transform: rotate(6deg)  skewY(-2deg); }
  100% { -webkit-transform: rotate(0deg)  skewY(0deg); }
}

@keyframes swing {
  0%   { transform: rotate(0deg)  skewY(0deg); }
  20%  { transform: rotate(12deg) skewY(4deg); }
  60%  { transform: rotate(-9deg) skewY(-3deg); }
  80%  { transform: rotate(6deg)  skewY(-2deg); }
  100% { transform: rotate(0deg)  skewY(0deg); }
}


.cal-course-wrap h3 a{
  color: #de0000 !important;
}
.contents-offset-top {
  position: relative;
  top:-40px;
  z-index:999;
}


/* BUTTONS */
.btn-primary{
  border: none !important;
}

/* About Page */
.about-text{
  padding: 5px 15px;
}
.about-social{
  text-align: center;
}
.about-social a{
  font-size: 50px;
  color: $color1;
}
.about-social a:hover {
  color: $color2;
}
.subscribe-title {
  padding-top:60px;
}


.about-team h3{
  color: #de0000 !important;
}
/* About Meet The Team */
.member-container{
  margin: 50px 20px;
  background-color: $color1;
  border:6px solid $color1;
}
.member-container:hover {
  background-color: #000;
  border-color: #000;
  cursor: pointer;
}
.member-image {
  height:160px;
  overflow:hidden;
  position: relative;
}
.member-image img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
.member-name{
  text-align: center;
  padding: 5px;
  color: #FFF;
  height:60px;
}
.about-team-member {
  display: none;
  position: relative;
  top:0px;
}

/* Paginator */
ul.pagination {
  display: inline-block;
  padding: 0;
  margin: 0;
  font-size: 22px;
}

ul.pagination li {
  display: inline;
  float: left;
  border-radius: 5px;

}
ul.pagination li span {
  margin: 0 4px; /* 0 is for top and bottom. Feel free to change it */
  padding: 8px 16px;
  display: inline-block;
}
ul.pagination li.active span {
  margin: 0 4px; /* 0 is for top and bottom. Feel free to change it */
  padding: 8px 16px;
  display: inline-block;
  background-color: #dedede;
}

ul.pagination li a {
  color: black;
  display: inline-block;
  text-decoration: none;
  margin: 0 4px; /* 0 is for top and bottom. Feel free to change it */
  padding: 8px 16px;
}
ul.pagination li a.active {
  background-color: #4CAF50;
  color: white;
}

ul.pagination li a:hover:not(.active) {
  background-color: $color1;
  color: white;
}


ul.pagination li a.active {
  border-radius: 5px;
}

/* Apprenticeships Page */
.testimonials-slider {
  width: 70%;
  margin: $mar auto 0 auto;
}
.testimonials-slider .slick-dots li button::before {
  color: #000000;
}
.testimonials-slider .slick-dots li.slick-active button::before {
  color: #000000;
}
.testimonial-slide {
  padding: $pad;
}
.testimonial-name {
  text-align: left;
  color: #000000;
  font-weight: bold;
  margin-top: $mar;
}
.testimonial-text {
  color: #000000;
  font-size: 20px;
}
.testimonial-year {
  text-align: left;
  color: #000000;
  font-weight: bold;
  font-size: 12px;
  margin-top: $mar;
}
.home-news-wrap {
  padding: $pad;
  margin: 0px $mar;
  background-color: rgba(255,255,255,0.8);
}